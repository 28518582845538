import React from "react";
import Img from "gatsby-image"
import { Link, graphql } from 'gatsby';
import Layout from "../layouts/layout";
import Video from "../components/video";
import Modal from 'react-modal';
import Timeline from "../components/timeline";


const Newsletter  = ({data: {newsletters}}) =>  {

    const hero = {};
    hero.heroHeading = 'Media';

    return(
        <Layout hero={hero}>
            <div className="container mx-auto mb-16 px-4">
                <h2 class="text-3xl sm:text-5xl font-bold dark-blue leading-tight">MAN News</h2>
                <div className="mt-4 sm:flex flex-wrap justify-between newsletters">
                    {newsletters && newsletters.edges.map(({node}) => (
                        <div className="cards-four sm:flex flex-col mt-8 sm:mt-12 text-center" key={node.id}>
                            <div className="w-4/5 mx-auto sm:w-full">
                                <Link target="_blank" to={node.pdf.file.url}>
                                    {node.thumbnail && 
                                        <Img className="object-top w-full h-full" fluid={node.thumbnail.fluid} />
                                    }
                                </Link>
                                <p className="mt-1 text-sm"><span className="mr-2 font-semibold">Issue #{node.issueNumber}</span>{node.date}</p>
                            </div>
                        </div>
                    ))
                    }
                </div>
            </div>
        </Layout>
    )};

export const query = graphql`
query getNewsletters {
   newsletters: allContentfulNewsletters (sort: {fields: issueNumber, order: DESC}) {
    edges {
        node {
           id
           title
           date (formatString: "MMM YYYY")
           issueNumber
           pdf {
            file {
              url
            }
           }
           thumbnail {
             fluid {
               ...GatsbyContentfulFluid_withWebp_noBase64
             }
           }
        }
    }
  }
}

`;


export default Newsletter;


